import React, { useState } from "react"
import styled from "@emotion/styled"

import RichText from "../../new-ui/rich-text"
import Typography from "../../ui/Typography"

import Link from "../../../utils/Link"
import { ContentfulTypes } from "../../../utils/types"

const Accordion = styled.div`
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }

  margin: auto;
  margin-bottom: 3rem;
  width: 100%;
`

const Card = styled.div``

const Body = styled.div`
  overflow: hidden;
  transition: height 0.3s ease-out;
  height: 0;
`

const Toggle = styled.div`
  ${Typography.Large}
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem 0;
  border-bottom: 1px solid black;

  i {
    transition: transform 400ms;
    transform: ${(props) => (props.expanded ? `rotate(180deg)` : `none`)};
  }
`

const AccordionModule = ({ input }) => {
  const { items } = input

  const [expandedSection, setSection] = useState(undefined)

  const onSetSection = (ref) => {
    const oldElement = expandedSection

    if (expandedSection) {
      let element = expandedSection

      const sectionHeight = element.scrollHeight
      const elementTransition = element.style.transition
      element.style.transition = ""

      requestAnimationFrame(function () {
        element.style.height = sectionHeight + "px"
        element.style.transition = elementTransition

        requestAnimationFrame(function () {
          element.style.height = 0 + "px"
        })
      })
      setSection(undefined)
    }

    if (
      !oldElement ||
      oldElement.getAttribute("id") !== ref.current.getAttribute("id")
    ) {
      let element = ref.current

      const sectionHeight = element.scrollHeight
      element.style.height = sectionHeight + "px"

      element.addEventListener("transitionend", function _listener() {
        element.removeEventListener("transitionend", _listener)
        element.style.height = "auto"
      })

      setSection(ref.current)
    }
  }

  return (
    <Accordion className="accordion">
      {items.map((input) => {
        const ref = React.createRef()
        if (input.internal?.type === ContentfulTypes.ContentfulRichText) {
          return (
            <Card key={input.id}>
              <Toggle
                expanded={
                  expandedSection &&
                  expandedSection.getAttribute("id") === input.id
                }
                onClick={() => onSetSection(ref)}
              >
                {input.title}
                <i className="fal fa-arrow-down" />
              </Toggle>
              <Body ref={ref} id={input.id}>
                <RichText noMargin input={input} />
              </Body>
            </Card>
          )
        }

        if (input.internal?.type === ContentfulTypes.ContentfulNavigationItem) {
          return (
            <Link key={input.id} to={input.linkTo}>
              <Toggle>
                {input.name}
                <i className="fal fa-arrow-right" />
              </Toggle>
            </Link>
          )
        }
      })}
    </Accordion>
  )
}

export default AccordionModule
